/*eslint-disable*/
import React from 'react';
import styled from 'styled-components/macro' // eslint-disable-line no-unused-vars
import { withRouter } from "react-router-dom"

import './App.css';
import Routes from "./Routes"
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'

import { StateProvider } from "./context/context";
import StreamProvider from "./containers/StreamProvider";
import { context } from './context/context';
import { getRandomBackground } from './utils'

const App = () => { 
  const childProps = {}
  return (
		<div className={getRandomBackground()} css="height:100vh">
			<div css="padding: 50px; height:100%;">
				<div css="margin: auto; padding:10px; background-color:white; color: black; height:100%;">
					<StateProvider>
						<StreamProvider>
							<Header />
							<div id="content" css="padding: 20px; height:100%;">
								<Routes childProps={childProps} />
							</div>
							<Footer />
						</StreamProvider>
					</StateProvider>
				</div>
		  </div>
		</div>
  )
}

export default withRouter(App)
