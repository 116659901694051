/*eslint-disable*/
import React from 'react';
import styled from 'styled-components/macro' // eslint-disable-line no-unused-vars
import * as R from 'ramda'
import Loader from '../components/Loader'
import { Actions, context } from '../context/context'
import config from '../config'

const initializeCamera = async dispatch => {

  const constraints = {
    audio: true,
    video: {
      width: 320,
      height: 240,
      frameRate: {
        ideal: 60,
        min: 1
      }
    }
  }
  const localStream = await navigator.mediaDevices.getUserMedia(constraints)
  dispatch({ type: Actions.localCameraInitialized, localStream })
}

const initializePeerJS = dispatch => {
  const peer = new Peer({
    //debug: 3,
    key: config.peerSecretKey,
    config: {
      'iceServers': [
        {urls: `stun:${config.turnServer}`},
        {urls: `turns:${config.turnServer}`, username:"test", credential:"test"},
      ]
    }
  })

  peer.on('open', function(id) {
    dispatch({ type: Actions.localPeerInitialized, peer })
  })

  peer.on('call', call => {
    /*
     we are considered a joiner in this block.

     when joiner connects to a room, it expects 
     to be called by other sessions that have already connected, aka, callers.
     this code detects call events and answers them.
    */
    call.answer(state.local.stream) //joiner answers caller's call
    call.on('stream', stream => {
      dispatch({ type: Actions.joinerReceivedCallerStream, peerId:call.peer, stream })
    })
  })

  peer.on('connection', conn => {  
    conn.on('open', () => {
      conn.on('data', data => {
        switch(data.type) {
          /*
          case 'nameChange':
            dispatch({ type:"setFaceName", id: data.peerID, name: data.name })
            break;
          */
          default:
            console.log("unknown data stream message", data)
        }	
      })
    })
  })
}

const StreamProvider = ( {children} ) => {
  /*
  the main purpose of this wrapper component is to wrap the whole App
  after initializing the local webcam and connecting to peerJS.
  */
  const { dispatch, state } = React.useContext(context);
  window.state = state
 
  React.useEffect( () => {
    //this block triggers whenever a new player joins
    state.joinerList.forEach( peerId => {
      const sessionId = state.peerIdsToSessionIds[peerId]
      if (state.sessions[sessionId]){
        return
      }
      const call = state.local.peer.call(peerId, state.local.stream)//caller calls joiner
      call.on('stream', stream => {
        //when caller receives joiner's stream from their answer, set the joiner's stream in context
        dispatch({ type: Actions.callerReceivedJoinerStream, peerId:call.peer, stream })
      })
    })
  }, [state.joinerList])

  React.useEffect(()=>{ 
    initializeCamera(dispatch)
    initializePeerJS(dispatch)
  }, [])

  if (!R.path(["local", "peer", "id"], state)){
    return <Loader />
  }

  return children
}
export default StreamProvider
