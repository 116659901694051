import React, { useContext, useEffect, useCallback, useState, useRef } from 'react'
import * as Colyseus from 'colyseus.js'
import styled from 'styled-components/macro'
import { Actions, context } from '../context/context'
import PixiScene from '../containers/scene/PixiScene'
import { VideoStream, VideoStreams } from '../components/Video/Video'
import config from '../config'

const wsPort = 3002

const homegrid = `
  display: grid;
  grid-template-areas: 
    'a'
    'b';
  grid-template-columns: 1fr;
  grid-gap: 5px;
 	height:100%;
`

export default function Room(props) {
  // pulled from the url as rooms/:roomName/:roomId?
  const { roomName, roomId } = props.match.params
  const { state, dispatch } = useContext(context)
  const [loading, setLoading] = React.useState(true)
  const [gameClient, setGameClient] = useState(null)
  const canvasContainer = useRef()
  const peerId = state.local.peer.id

  useEffect(() => {
    //const wsHost = window.document.location.host.replace(/:.*/, '')
    setGameClient(new Colyseus.Client(config.colyseusServer))
  }, [])

  useEffect(() => {
    if (state.local.video.current){
      setLoading(false)
    }
  }, [state.local.video])

  return (
    <div css={homegrid}>
      <span css="border: solid black 1px; padding: 5px">
        {`PeerID:${peerId} SocketID:${state.peerIdsToSessionIds[peerId]}`}
        <VideoStream id="local_stream" peerId={peerId} stream={state.local.stream}/>
        <VideoStreams id="remote_streams" sessions={state.sessions} />
      </span> 
      
      <div ref={canvasContainer} css="grid-area: b; border: solid black 1px; height:100%">
        {!loading && gameClient && canvasContainer.current &&
          <PixiScene
            {...props}
            container={canvasContainer}
            roomName={roomName}
            roomId={roomId}
            client={gameClient}
            local={{ ...state.local, peerId }}
            sessions={state.sessions}
          />
        }
      </div>
    </div>
  )
}
