import React, { useRef, useEffect } from 'react'
import { Actions, context } from '../../context/context'

// We should try to avoid directly referencing ctx in a component.
// Hopefully when everything is wired up it can just be used like this:
//
// ctx.state.sessions.map(s => (
//   <StreamVideo
//     key={s.id}
//     stream={s.stream}
//     volume={s.volume} // just works like a normal prop
//   />
// ))

export function VideoStream({ style={}, peerId, stream, volume, width="0" }) {
  const { state, dispatch } = React.useContext(context)
  const local = peerId === state.local.peer.id
  const videoRef = useRef(null)

  useEffect(() => {
    if (stream) {
      videoRef.current.srcObject = stream
      dispatch({ type: Actions.videoCreated, peerId, video: videoRef })
    }
  }, [stream])

  useEffect(() => {
    if (local) { 
      videoRef.current.volume = 0 
    } else {
      videoRef.current.volume = volume || 1
    }
  }, [volume])

  return (
    <video
      style={style}
      autoPlay
      playsInline
      ref={videoRef}
      width={width}
    />
  )
}

export function VolumeControlledVideo(props) {
	const { peerID, stream, videoRef, initVolume=1 } = props
  //const ctx = React.useContext(context);

  //React.useEffect(() => {
    //videoRef.current.srcObject = stream
		//const setVolume = v => {
			////this is a dirty hack, i wasnt able to get React.useState to work for this
			//const vid = document.getElementById("video-"+peerID)
			//vid.volume = v
		//}
		//setVolume(initVolume)
    //ctx.dispatch({type:"setPeerVolume", peerID, setVolume })
	//}, [])

	return (
		<video 
			width="1" 
			ref={videoRef} 
			id={`video-${peerID}`} 
			autoPlay 
			playsInline 
		/>
	)
}

export function VideoStreams({ sessions }) {
  const { state } = React.useContext(context)
  return (
    <span>
      {Object.entries(state.sessions).map(([sessionId, session]) => {
        if (state.local.sessionId === sessionId){ return }
        return <VideoStream key={sessionId} peerId={session.peerId} stream={session.stream} />
      })}
    </span>
  )
  
}
