import React from "react";
import { Route, Switch } from "react-router-dom"
import Lobby from './containers/Lobby'
import Room from './containers/Room'

const PublicRoute = ({ component: C, props: cProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => <C {...props} {...cProps} />}
    />
  )
}

const Routes = ({ childProps }) => {
  return (
    <Switch>
      <PublicRoute path='/room/:roomName/:roomId?' component={Room} props={childProps} />
      <PublicRoute path='/' exact component={Lobby} props={childProps} />
    </Switch>
  )
}

export default Routes
