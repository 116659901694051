import React from "react";
import styled from 'styled-components/macro' // eslint-disable-line no-unused-vars

const Loader = () => {
  return (
    <div css="text-align:center;padding-top:20px">
      loading...
    </div>
  )
}

export default Loader

