import * as Pixi from 'pixi.js'
import { Viewport } from 'pixi-viewport'

export class World {
  app = null
  viewport = null
  client = null
  room = null
  players = {}
  playerOne = null
  input = new PlayerInput()
  roomName = null
  roomId = null
  onPlayerJoined = () => {}
  onPlayerListReceived = () => {}
  local = {} 
  sessions = {}
  players = {}
  pixiConfig = {}

  constructor(config, pixiConfig) {
    this.client = config.client
    this.local = { ...config.local }
    this.sessions = { ...config.sessions }
    this.roomName = config.roomName
    this.roomId = config.roomId
    this.onPlayerJoined = config.onPlayerJoined
    this.onPlayerListReceived = config.onPlayerListReceived
    this.pixiConfig = pixiConfig

    this.app = new Pixi.Application({
      backgroundColor: 0x666666,
      antialias: true,
      autoDensity: true,
      ...pixiConfig
    })


  }



  async connectClient() {
    this.room = await this.client.joinOrCreate(this.roomName, { peerId: this.local.peerId })

    this.playerOne = new Player('boo', this.local.video.current)
    this.playerOne.setPosition(this.app.renderer.width / 2, this.app.renderer.height / 2)

    //this.room.onAdd = this.onAddPlayer.bind(this)
    this.room.onAdd = (a, b) => { console.log('world.onAdd', a, b) }
    this.room.state.players.onChange = this.playerUpdate.bind(this)
    this.app.ticker.add(this.render.bind(this))
        this.room.onMessage('welcome', message => {
      this.onPlayerJoined(message)
    })
    this.room.onMessage('initialize', ({ width, height, playerList }) => {
      this.onPlayerListReceived(this.room.sessionId, playerList)
      this.initializeScene({ width, height })
    })
  }

  addSessions(sessions) {
    for (let id in sessions) {
      if (!this.players[id]) {
        const session = sessions[id]
        if (session.video && session.video.current !== null) {
          this.sessions[id] = session
          this.players[id] = new Player(id, session.video.current)
          this.players[id].setPosition(this.app.renderer.width / 2, this.app.renderer.height / 2)
          this.viewport.addChild(this.players[id].getContainer())
        }
      }
    }
  }

  render() {
    if (this.playerOne) {
      this.playerOne.update(this.app.ticker)
    }
    Object.keys(this.players).forEach(id => {
      this.players[id].update(this.app.ticker)
    })

    const i = this.input.get()
    if (i.up || i.down || i.left || i.right) {
      this.room.send('input', i)
    }
  }

  playerUpdate(player, sessionId) {
    // trash soon
    if (player.peerId === this.local.peerId) {
      this.playerOne.setTargetPosition(player.x, player.y)
    }
    else if (this.players[sessionId]) {
      this.players[sessionId].setTargetPosition(player.x, player.y)
    }
  }

  initializeScene({ width, height }) {

    this.viewport = new Viewport({
      screenWidth: this.pixiConfig.width,
      screenHeight: this.pixiConfig.height,
      worldWidth: width,
      worldHeight: height,
      interaction: this.app.renderer.plugins.interaction
    })
    this.app.stage.addChild(this.viewport)
    this.viewport.drag().pinch().decelerate()
      
    const background = Pixi.Sprite.from('https://' + window.document.location.host + '/LightOverworld-square.png')
    background.anchor.x = 0
    background.anchor.y = 0
    background.position.x = 0
    background.position.y = 0
    background.scale.x = .5
    background.scale.y = .5
    this.viewport.addChild(background)

    this.viewport.addChild(this.playerOne.getContainer())
    this.viewport.follow(this.playerOne.getContainer(), { radius: 400 })
  }
}

class Player {
  smoothing = true
  container = null
  graphics = null
  sprite = null
  videoTexture = null
  updatedAt = Date.now()
  lastUpdated = Date.now()
  previousTarget = { x: 0, y: 0 }
  targetPosition = { x: 0, y: 0 }

  basePosition = { time: Date.now(), diff: 0, x: 0, y: 0 }
  positionBuffer = [
    this.basePosition, this.basePosition
  ]

  constructor(id, video) {
    this.container = new Pixi.Container()
    this.videoTexture = Pixi.Texture.from(video)

    this.graphics = new Pixi.Graphics()
      .beginFill(0x000000)
      .lineStyle(0)
      .drawCircle(0, 0, 100)
      .endFill()

    this.sprite = new Pixi.Sprite(this.videoTexture)
    this.sprite.mask = this.graphics

    this.container.addChild(this.sprite)
    this.container.addChild(this.graphics)

    this.sprite.anchor.x = .5
    this.sprite.anchor.y = .5

    this.container.scale.x = -1
    this.container.scale.y = 1
  }

  update(ticker) {
    if (!this.smoothing) {
      this.container.position.x = this.positionBuffer[1].x
      this.container.position.y = this.positionBuffer[1].y
      return
    }
    const now = Date.now()

    let [prev, target] = this.positionBuffer
    let progress = now - target.time
    //let a = Math.min(1, progress / target.diff)
    let a = target.diff ? progress / target.diff : 1

    this.container.position.x = lerp(prev.x, target.x, a)
    this.container.position.y = lerp(prev.y, target.y, a)
  }

  setPosition(x, y) {
    this.container.position.x = x
    this.container.position.y = y
    this.targetPosition = { x, y }
  }

  setTargetPosition(x, y) {

    const time = Date.now()
    const diff = time - this.positionBuffer[1].time
    const newTargetPosition = { time, diff, x, y }

    this.positionBuffer = [this.positionBuffer[1], newTargetPosition]
  }

  getContainer() {
    return this.container
  }

}

export function PlayerInput(socket) {
  let up = false
  let down = false
  let left = false
  let right = false

  function setMovement(val, e) {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        up = val
        break
      case 'w':
        up = val
        break
      case 'ArrowDown':
        e.preventDefault()
        down = val
        break
      case 's':
        down = val
        break
      case 'ArrowLeft':
        e.preventDefault()
        left = val
        break
      case 'a':
        left = val
        break
      case 'ArrowRight':
        e.preventDefault()
        right = val
        break
      case 'd':
        right = val
        break
      default:
        break
    }
  }

  window.addEventListener('keydown', e => setMovement(true, e))
  window.addEventListener('keyup', e => setMovement(false, e))

  const get = () => ({ up, down, left, right })

  return { get }
}

function dist(p1, p2) {
  return Math.sqrt(((p2.x - p1.x)**2) + ((p2.y - p1.y)**2))
}
function lerp(v0, v1, t) {
  return v0*(1-t)+v1*t
}

