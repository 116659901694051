import React from 'react';
import styled from 'styled-components/macro' // eslint-disable-line no-unused-vars

const Footer = () => {
  return (
    <div css="text-align: center">
      <div css="margin: auto; width:200px; background: white"> 
        2020
      </div>
    </div>
  )
}
export default Footer
