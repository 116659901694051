import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Actions, context } from '../../context/context'
import { World } from '../../simulations'

export default function PixiScene(props) {
  const { dispatch, state } = useContext(context)

  const { container, client, local, sessions, roomName, roomId } = props
  const [world, setWorld] = useState(null)

  const onPlayerJoined = ({ peerId, sessionId }) => {
    dispatch({ type: Actions.newPlayerJoinedRoom, peerId, sessionId, roomName, roomId })
  }

  const onPlayerListReceived = (sessionId, playerList) => {
    dispatch({ type: Actions.playerListReceived, sessionId, peerIdsToSessionIds: playerList })
  }

  const onCanvas = useCallback(view => {
    if (view === null) { return }
    const { offsetWidth, offsetHeight } = container.current

    setWorld(
      new World({
        client,
        local,
        sessions,
        roomName,
        roomId,
        onPlayerJoined,
        onPlayerListReceived
      },
      {
        view,
        width: offsetWidth,
        height: offsetHeight
      })
    )
  }, [])

  useEffect(() => {
    if (world === null) { return }
    if (client === null) { return }
    if (local.video.current === null) { return }

    console.log('connecting world:', roomName)
    world.connectClient()
    //dispatch({ type: Actions.roomJoined, roomName, roomId })
  }, [world, client, roomName, roomId])

  useEffect(() => {
    if (world) {
      world.addSessions(state.sessions)
    }
  }, [state.sessions, world])

  return (
    <canvas ref={onCanvas} style={{ width: '100%' }}></canvas>
  )
}

