import React, { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Actions, context } from '../context/context'
import { VideoStream } from '../components/Video/Video'

// share something like this between server and client?
const defaultRooms = [
  'normies',
  'edgelords',
  'edgeladies',
  'boomers',
  'zoomers'
]

export default function Lobby(props) {
  const { state, dispatch } = useContext(context)
  const [name, setName] = useState(state.local.name)
  const [showBrowser, setShowBrowser] = useState(false)
  const [rooms] = useState(defaultRooms)

  const onNameUpdated = name => {
    setName(name)
    dispatch({ type: Actions.nameUpdated, name })
  }

  return (
    <div>
      <VideoStream style={{ transform: 'rotateY(180deg)' }} width="500" peerId={state.local.peer.id} stream={state.local.stream} />

      <div>
        <input
          placeholder="Your Name"
          type="text"
          value={name}
          onChange={e => onNameUpdated(e.target.value)}
        />
        <button onClick={() => setShowBrowser(true)}>
          next
        </button>
      </div>

      {showBrowser &&
        <div>
          <h4>Choose a room or make your own:</h4>
          <div>
            {rooms.map((room, i) => (
              <div key={i}>
                <Link to={`/room/${room}`}>{room}</Link>
              </div>
            ))}

          </div>
        </div>
      }

    </div>
  )
}
