
const sizes = ['sm', 'md', 'lg', 'xl']
const patterns = ['checks', 'grid', 'dots', 'cross-dots', 'diagonal-lines', 'horizontal-lines', 'vertical-lines', 'diagonal-stripes', 'horizontal-stripes', 'vertical-stripes', 'triangles', 'zigzag']
const colors = ["mint", "blue", "black", "yellow", "grey"]

const rInt = n => Math.floor(Math.random() * n)

export const getRandomBackground = () => {
  const size = sizes[rInt(sizes.length-1)]
  const pattern = patterns[rInt(patterns.length-1)]
  const priColor = colors[rInt(colors.length-1)]
  let secColor = colors[rInt(colors.length-1)]
  while (secColor === priColor){
    secColor = colors[rInt(colors.length-1)]
  }
  const css = `pattern-${pattern}-${size} bg-${priColor} ${secColor}`
  console.log(css)
  return css
}
