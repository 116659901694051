import React from 'react';
import styled from 'styled-components/macro' // eslint-disable-line no-unused-vars

const Header = () => {
  return (
    <div>
      webrtc demo
    </div>
  )
}
export default Header
