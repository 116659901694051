/*eslint-disable*/
import React from 'react'
import * as R from 'ramda'
import Loader from '../components/Loader'

const initialState = {

  local: {
    name: '',
    sessionId: null,
    stream: null,
    video: {},
    peer: {},
  },
  sessions: {},
  peerIdsToSessionIds: {},
  joinerList: [],
}

const context = React.createContext(initialState)
const { Provider } = context;

const Actions = {
  localCameraInitialized: 'LOCAL_CAMERA_INITIALIZED',
  localPeerInitialized: 'LOCAL_PEER_INITIALIZED',

  callerReceivedJoinerStream: 'CALLER_RECEIVED_JOINER_STREAM',
  joinerReceivedCallerStream: 'JOINER_RECEIVED_CALLER_STREAM',

  videoCreated: 'VIDEO_CREATED',
  nameUpdated: 'NAME_UPDATED',
  newPlayerJoinedRoom: 'NEW_PLAYER_JOINED_ROOM',
  playerListReceived: 'PLAYER_LIST_RECEIVED'
}

const reducer = (state, action) => {
  console.log('REDUCER:', action.type)

  switch (action.type) {
    case Actions.localCameraInitialized: {
      return {
        ...state,
        local: {
          ...state.local,
          stream: action.localStream,
        }
      }
    }
    case Actions.nameUpdated: {
      return {
        ...state,
        local: {
          ...state.local,
          name: action.name
        }
      }
    }
    case Actions.videoCreated: {
      if (action.peerId === state.local.peer.id) {
        const local = { ...state.local, video: action.video }
        return { ...state, local } 
      }
      const sessionId = state.peerIdsToSessionIds[action.peerId]
      if (!sessionId) {
        throw new Error('Could not find session ID for peer ID: ' + action.peerId)
      }

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionId]: {
            ...state.sessions[sessionId],
            video: action.video
          }
        }
      }
    } 
    case Actions.localPeerInitialized: {
      return {
        ...state,
        local: {
          ...state.local,
          peer: action.peer,
        }
      }
    }
    case Actions.callerReceivedJoinerStream: {
      const { peerId, stream } = action
      const session = state.sessions[peerId]
      if (session){ return state } //only do this once per peer
      //console.log("caller received joiner's stream", peerId, remoteStream)
      const joinerList = R.without([...state.joinerList], [peerId])
      const sessionId = state.peerIdsToSessionIds[peerId]

      return {
        ...state,
        joinerList,
        sessions: {
          ...state.sessions,
          [sessionId]: { peerId, stream }
        }
      }
    }
    case Actions.joinerReceivedCallerStream: {
      const { peerId, stream } = action
      const session = state.sessions[peerId]
      if (session){ return state } //only do this once per peer
      //console.log("joiner received caller's stream", peerId, remoteStream)
      const sessionId = state.peerIdsToSessionIds[peerId]

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionId]: { peerId, stream }
        }
      }
    }
    case Actions.newPlayerJoinedRoom: {
      const { peerId, sessionId, roomName, roomId } = action

      //only add others to the joiner list
      let joinerList = [...state.joinerList]
      if (peerId !== state.local.peer.id){
        joinerList.push(peerId)
      }
      return {
        ...state,
        joinerList,
        peerIdsToSessionIds: {
          ...state.peerIdsToSessionIds,
          [peerId]: sessionId
        }
      }
    }
    case Actions.playerListReceived: {
      const { sessionId, peerIdsToSessionIds } = action
      return {
        ...state,
        local: {
          ...state.local,
          sessionId
        },
        peerIdsToSessionIds
      }
    }
    default: {
      console.log("unknown action: ", action)
      return state
    }
  }
}

const StateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { Actions, context, StateProvider }
